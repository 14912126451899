import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Grid, Icon, List, Popup} from "semantic-ui-react";
import {CustomContainer} from '../custom-common';
import {Button} from "../base";
import {connect} from "react-redux";
import {CHANGE_ROUTE, convertServerDateFormatToDisplay, formatMoneyAmount, getNumberOfDeductions, memberName, resolveDisplayTier} from "../../utils/common";
import {setClaimant} from "../../actions/newClaimActions";
import NumberFormat from "react-number-format";
import CoveredModal from "../coveredModal";
import {getPayrollFrecuencyLabel} from "../../utils/utils";
import Header3Blue from "./common/header3Blue";
import PlanDocuments from "./planDocuments";
import STRATEGIC_PARTNER from "../../constants/strategicPartners";
import Header4Black from "./common/header4Black";
import Header2Black from "./common/header2Black";
import {PAYROLL_FREQUENCIES} from "../../constants/payrollFrequencies";
import {S3Image} from "../custom-common";
import {CustomDivider} from "../custom-common";
import BenefitLimits from "./benefitLimits";

const mapStateToProps = (state, ownProps) => {
    const id = state.initialData || {}

    return {
        ...ownProps,
        dependents: id.dependents,
        certificateHolder: id.certificateHolder,
        user: id.user,
        benefits: id.certificate.assetCoverages,
        limitations: id.limitations,
        certificate: id.certificate,
        payrollFrequency: id.payrollFrequency,
        cost: id.cost,
        employerContribution: id.employerContribution,
        userIsCertificateHolder: id.userIsCertificateHolder,
        contract: id.contract,
    }
};

const Benefit = ({value, name, tooltipText, onClick}) => {
    const color = '#616161';
    const {PRIMARY} = STRATEGIC_PARTNER.PALETTE;

    return (
        <>
            <Header4Black>
                <NumberFormat value={value}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}/>
            </Header4Black>
            <p><span style={{color:PRIMARY}}>{name}</span>
                <Popup position={'top left'}
                       className={'greenPopup'}
                       on={'hover'}
                       style={{cursor: 'pointer', color}}
                       trigger={
                           <span style={{color}}>
                         <Icon className={'nonprintable'} circular name={'info'}
                               style={{color, marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}
                               onClick={onClick}/></span>
                       }>
                    <span className={"smaller"} style={{color}}>{tooltipText}</span>
                </Popup>
            </p>
        </>
    );
};


class PlanSummary extends Component {
    state = {
        loading: true,
        docsLoading: false,
        docsError: null,
        displayTier: null,
        displayPayrollFrecuency: null,
        selectedRow: null,
        displayCoveredModal: false
    }

    doDisplayCoveredModal = (status) => {
        let {displayTipsModal} = this.state;
        if (status) {
            displayTipsModal = false;
        }
        this.setState({
            displayTipsModal,
            displayCoveredModal: status,
        });
    }

    componentDidMount() {
        this.setState({
            loading: false,
            displayTier: !!this.props.certificate ? resolveDisplayTier(this.props.certificate.familyTier) : '',
            displayPayrollFrecuency: getPayrollFrecuencyLabel(this.props.payrollFrequency, true)
        })
    }

    handleChange = (claimant) => {
        this.props.dispatch(setClaimant(claimant))
        this.props.history.push("/claims/new")
    }

    toggleDetail = (key) => {
        const {selectedRow} = this.state
        if (key === selectedRow) {
            key = null
        }
        this.setState({selectedRow: key})
    }

    change = () => {
        const {certificate, contract} = this.props;
        if (!!certificate.useBrellaEnrollment && contract.planOffering !== "Brella Select Custom") {
            this.props.history.push(CHANGE_ROUTE)
        } else {
            this.props.history.push("/benAdmin")
        }
    }

    render() {
        const {
            displayTier,
            displayPayrollFrecuency,
            displayCoveredModal
        } = this.state;
        const {
            dependents,
            user,
            benefits,
            payrollFrequency,
            limitations,
            employerContribution,
            cost,
            userIsCertificateHolder,
            certificate,
        } = this.props;
        const {contractTerms} = certificate.ratedClass;
        const {PRIMARY} = STRATEGIC_PARTNER.PALETTE
        const onClickInfo = () => this.setState({displayInformationAboutCategoriesModal: true});

        const infoIcon = <Icon className={'nonprintable'} circular name={'info'}
                               style={{color: '#616161', marginLeft: '1em', fontSize: '10px', cursor: 'pointer'}}
                               onClick={onClickInfo}/>;

        const moderateTooltipText = "Injuries or illnesses that likely require a short visit to the ER or urgent care."
        const costsTooltipText = !!payrollFrequency ? `Estimated based on a payroll frequency of ${getNumberOfDeductions(payrollFrequency)} payroll deductions per year, and may not match
                                    exact payroll withdrawals. Contractual premiums are on a monthly basis.` : null;
        const severeTooltipText = "Serious conditions that require more intensive medical treatment and attention."
        const catastrophicTooltipText = "Dangerous or life-threatening conditions that require immediate medical intervention."
        const chronicTooltipText = "Coverage for over 4,100 additional chronic conditions spanning asthma, diabetes, epilepsy, and many more."
        const mentalTooltipText = "Coverage for 600 different mental health disorders like anxiety, bipolar, and depression."

        const displayInfoIconIfNecessary = () => {
            if (payrollFrequency === PAYROLL_FREQUENCIES.MONTHLY_12) return null;

            return (
                <Popup className="greenPopup" on="hover" position="top left"
                       trigger={<span style={{display: 'flex'}}>{infoIcon}</span>}>
                    <span className="smaller">{costsTooltipText}</span>
                </Popup>
            );
        };

        return (<>
                <CoveredModal displayCoveredModal={displayCoveredModal}
                              onModalClose={() => this.doDisplayCoveredModal(false)}
                />
                <p className={"small"}><b>Note:</b> This is a summary of the important features of your
                    coverage. This is not the insurance contract. Please refer to your certificate below for all
                    details including the rights and obligations of both you and {STRATEGIC_PARTNER.LABEL}.</p>
                <CustomDivider hidden/>
                <Header3Blue>Coverage</Header3Blue>
                {!!certificate &&
                <p className={"small"}><b>Effective {convertServerDateFormatToDisplay(certificate.effectiveDate)}</b></p>}
                <Button data-qa-button_condition primary basic compact onClick={() => {
                    this.setState({displayCoveredModal: true})
                }}>View all conditions</Button>
                <CustomDivider small hidden/>

                {!!benefits && <Grid>
                    <Grid.Column width={7}>
                        <Header4Black>Benefit categories</Header4Black>
                        <CustomContainer padded>
                            <Grid columns={3} stackable>
                                <Grid.Column style={{paddingRight: 0}}>
                                    <Benefit
                                        value={benefits.moderate}
                                        name={'Moderate'}
                                        tooltipText={moderateTooltipText}
                                        onClick={onClickInfo}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Benefit
                                        value={benefits.severe}
                                        name={'Severe'}
                                        tooltipText={severeTooltipText}
                                        onClick={onClickInfo}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{paddingRight: 0, paddingLeft: 0}}>
                                    <Benefit
                                        value={benefits.catastrophic}
                                        name={'Catastrophic'}
                                        tooltipText={catastrophicTooltipText}
                                        onClick={onClickInfo}
                                    />
                                </Grid.Column>
                            </Grid>
                        </CustomContainer>
                    </Grid.Column>
                    {(!!benefits.chronic || !!benefits.mental) && <Grid.Column width={6}>
                        <Header4Black>
                            Optional riders
                        </Header4Black>
                        <CustomContainer padded>
                            <Grid columns={3} stackable>
                                {!!benefits.chronic &&
                                <Grid.Column>
                                    <Benefit
                                        value={benefits.chronic}
                                        name={'Chronic'}
                                        tooltipText={chronicTooltipText}
                                        onClick={onClickInfo}
                                    />
                                </Grid.Column>}
                                {!!benefits.mental &&
                                <Grid.Column>
                                    <Benefit
                                        value={benefits.mental}
                                        name={'Mental'}
                                        tooltipText={mentalTooltipText}
                                        onClick={onClickInfo}
                                    />
                                </Grid.Column>}
                            </Grid>
                        </CustomContainer>
                    </Grid.Column>}
                </Grid>}

                {!!userIsCertificateHolder && <>
                    <CustomDivider hidden/>
                    <Header3Blue>Family info</Header3Blue>
                    <Grid>
                        <Grid.Column width={4}>
                            <p>
                                <b>Tier</b><br/>
                                {displayTier}
                            </p>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div>
                                <div><b>Covered</b></div>
                                <div>{memberName(user)}</div>
                                {(!!dependents && dependents.length > 0) && dependents.map((item, i) =>
                                    <div key={i}>{item.firstName} {item.lastName}</div>)}
                            </div>
                        </Grid.Column>
                    </Grid>
                </>
                }
                {!!userIsCertificateHolder && <>
                    <CustomDivider section/>
                    <Header3Blue>Costs</Header3Blue>
                    <CustomDivider hidden small/>

                    <Grid>
                        <Grid.Column width={6}>
                            {
                                formatMoneyAmount(cost) === "0.00"
                                    ? (
                                        <CustomContainer padded
                                                 style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <S3Image name={"icon_100_percent"} width={80} centered/>
                                            <b style={{marginLeft: '25px'}}>
                                                Your {STRATEGIC_PARTNER.LABEL} coverage is paid for by your employer.
                                            </b>
                                        </CustomContainer>
                                    ) : (
                                        <>
                                            <CustomContainer data-qa-cost_info padded>
                                                <span style={{color: PRIMARY}}><b>I pay</b></span>
                                                <Header2Black style={{display: 'flex', alignItems: 'center'}}>
                                                    <span>${formatMoneyAmount(cost)}/{displayPayrollFrecuency}</span>
                                                    {displayInfoIconIfNecessary()}
                                                </Header2Black>
                                            </CustomContainer>
                                            {employerContribution > 0 && <p data-qa-cost_contribution className={'neutral900Text'}>Employer contributes
                                                ${formatMoneyAmount(employerContribution)}/{displayPayrollFrecuency}</p>}
                                        </>
                                    )
                            }
                        </Grid.Column>
                    </Grid>
                </>
                }
                <CustomDivider section/>
                <PlanDocuments />
                <CustomDivider section/>

                <BenefitLimits />

                <CustomDivider section/>
                <Header3Blue>Limitations and exclusions</Header3Blue>
                <p>A complete description of benefits, limitations, and exclusions will be provided in your
                    certificate of Insurance and applicable Riders. All coverage is subject to the terms and
                    conditions of the master group policy. We’ve provided a summary below.</p>
                <CustomDivider hidden/>
                {limitations.length && <>
                    <p><b>Exclusions</b></p>

                    {limitations.map((item, key) => (
                    <div key={key}>
                        <p>{item.title}</p>
                        <List className={'exclusionsList'} style={{marginLeft: '2em', marginBottom: '2em', fontWeight: 'bold'}}>
                            {item.items.map(({bullet, title}) => (
                                <List.Item key={bullet} data-bullet={bullet}>
                                    <span style={{fontWeight: 'normal'}}>{title}</span>
                                </List.Item>
                            ))}
                        </List>
                    </div>))}
                </>}

                {(!!contractTerms && !!contractTerms.ageReduction) && <><p>
                    <b>Reductions of coverage</b><br/>
                    Benefits will be reduced at ages beyond standard retirement age. At
                    age {contractTerms.ageReductionAge}, your benefits
                    reduce by {contractTerms.ageReductionPercentage}%.
                </p>
                    <CustomDivider hidden/>
                </>
                }

            {(!!contractTerms && contractTerms.benefitWaitingPeriod > 0) && <><p>
                    <b>Waiting periods</b><br/>
                    If you enroll or add a family member outside of an open enrollment period (annual, new hire,
                    or other qualifying life event), your benefits or any increases are subject to
                    a {contractTerms.benefitWaitingPeriod}-day
                    waiting period from effective date of change. Any diagnoses that occur during the waiting
                    period will not be covered.
                </p>
                    <CustomDivider hidden/>
                </>
            }

                <p>
                    <b>Renewability, cancellation, & termination</b><br/>
                    Our plans are group policies, and are annually renewable. Your continued coverage depends on
                    whether your employer renews the group policy. If your employer does not renew the policy,
                    coverage ends for all employees.&nbsp;

                    {(!!contractTerms && !!contractTerms.portability && contractTerms.portability !== 'Excluded') && <>
                        <span>You may be eligible to continue, or “port”, your coverage if you leave your
                        employer or if your employer discontinues the group policy. </span>
                    </>}

                    If your employer renews the policy, you will have the
                    option to continue your coverage. Premiums may change on the policy anniversary and your
                    employer’s contribution to policy premiums may also change. You may choose to terminate
                    coverage before the next policy anniversary by letting your employer know your choice in
                    writing.
                </p>
            </>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PlanSummary));
