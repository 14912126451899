import React, {Component} from 'react';
import {List, Modal,} from "semantic-ui-react";
import {Header} from "./base";
import Conditions from "./whatsCovered/conditions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import STRATEGIC_PARTNER from "../constants/strategicPartners";
import {CustomModalHeader} from './custom-common';


const mapStateToProps = (state, ownProps) => {
    const {initialData} = state;
    const {certificate, limitations, benefitLimits} = initialData;
    const {contractTerms} = certificate.ratedClass;

    return {
        ...ownProps,
        ageReduction: contractTerms.ageReduction,
        contractTerms,
        limitations,
        benefitLimits,
    }
};

class CoveredModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderLimitationsAndExclusionsModal: false,
        }
    }

    renderLimitationsExclusionsContent = () => {
        const {ageReduction, contractTerms, limitations, benefitLimits} = this.props;

        return <React.Fragment>
            <Header as={'h3'}>Limitations and exclusions</Header>
            <p>A complete description of benefits, limitations, and exclusions will be provided in your certificate of
                Insurance and applicable Riders. All coverage is subject to the terms and conditions of the master group
                policy. We’ve provided a summary below.</p>

            <p><b>Benefit details</b></p>
            <p>{STRATEGIC_PARTNER.LABEL} will pay a benefit if you are or your insured dependent is diagnosed with a covered condition. You may receive a benefit more than once as noted below{ benefitLimits.subjectToText }. { benefitLimits.adminChangeText }</p>
            <div style={{marginLeft: '1.5em'}}>
                { benefitLimits?.explanations?.map(({title, description}) =>
                    <p><b>{title}:</b><br/>{description}</p>
                )}
            </div>

            <br/>
            <p style={{fontSize: '16px'}}><b>Exclusions</b></p>
            {limitations.map((item, key) => (
                <div key={key}>
                    <p>{item.title}</p>
                    <List className={'exclusionsList'} style={{marginLeft: '2em', marginBottom: '2em', fontWeight: 'bold'}}>
                        {item.items.map(({bullet, title}) => (
                            <List.Item key={bullet} data-bullet={bullet}>
                                <span style={{fontWeight: 'normal'}}>{title}</span>
                            </List.Item>
                        ))}
                    </List>
                </div>
            ))}

            {!!ageReduction &&
                <React.Fragment>
                    <br/>
                    <p style={{fontSize: '16px'}}><b>Reductions of coverage</b></p>
                    <p>Benefits will be reduced at ages beyond standard retirement age. At age {contractTerms.ageReductionAge}, your benefits reduce
                        by {contractTerms.ageReductionPercentage}%.</p>
                </React.Fragment>}

            <br/>

            <p style={{fontSize: '16px'}}><b>Renewability, cancellation, & termination</b></p>
            <p>Our plans are group policies, and are annually renewable. Your continued coverage depends on whether your
                employer renews the group policy. If your employer does not renew the policy, coverage ends for all
                employees.&nbsp;
                {(!!contractTerms && !!contractTerms.portability && contractTerms.portability !== 'Excluded') && <>
                        <span>You may be eligible to continue, or “port”, your coverage if you leave your
                        employer or if your employer discontinues the group policy. </span>
                </>}
                If your employer renews the policy, you will have the option to continue your coverage.
                Premiums may change on the policy anniversary and your employer’s contribution to policy premiums may
                also change. You may choose to terminate coverage before the next policy anniversary by letting your
                employer know your choice in writing.</p>
            <br/>
        </React.Fragment>
    }

    handleScrollTop = () => {
        const modal = document.getElementById('modalContent');
        modal.scrollTo({
          top: 0,
          left: 0,
        });
    };

    showLimitationsAndExlusionContent = () => {
        this.setState({renderLimitationsAndExclusionsModal: true});
        this.handleScrollTop();
    }

    render() {
        const {renderLimitationsAndExclusionsModal} = this.state;
        const {displayCoveredModal, onModalClose: propsOnModalClose} = this.props;

        const onModalClose = () => {
            this.setState({renderLimitationsAndExclusionsModal: false});
            propsOnModalClose();
        }

        let title = <div className='conditionListTitle'>Conditions list</div>;
        if (renderLimitationsAndExclusionsModal) {
            title = <div style={{cursor: 'pointer'}}
                         onClick={() => {
                             this.setState({renderLimitationsAndExclusionsModal: false});
                         }}>
                <span>{'<'}{' '}</span><span style={{color: STRATEGIC_PARTNER.PALETTE.SECONDARY}}>Back to Conditions list</span>
            </div>;
        }

        return (
            <React.Fragment>
                <Modal style={{marginTop: '1rem'}}
                       onClose={onModalClose}
                       open={displayCoveredModal}>
                    <CustomModalHeader title={title} onClose={onModalClose} className="customModalHeader" />
                    <Modal.Content style={{minHeight: '45em', maxHeight: '45em', overflowY: 'auto'}} id="modalContent">
                        {renderLimitationsAndExclusionsModal ? (
                            this.renderLimitationsExclusionsContent()
                        ) : (
                            <Conditions/>
                        )}
                    </Modal.Content>

                    <Modal.Actions style={{backgroundColor: 'white', textAlign: 'left', borderTop: 'unset'}}>
                        {!renderLimitationsAndExclusionsModal &&
                            <a className={'small'} onClick={() => this.showLimitationsAndExlusionContent()}>
                                View Limitations and Exclusions→</a>
                        }
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CoveredModal));
